body {
  /* background: #36D1DC; */
  /* background-image: url("/public/logo.jpeg"); */
  /* background-repeat: repeat; */
  /* background-size: 250px; */
  background: -webkit-linear-gradient(to right, #5B86E5, #36D1DC);
  background: linear-gradient(to right, #5B86E5, #36D1DC);
  padding: 5vw 10%;
  font-family: sans-serif;
  font-size: 20px;
 	/* height: 100%; */

  @media screen and (max-width: 1000px) {
    padding: 2vh;
  }
}

.container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.glassMorph {
  /* From https://css.glass */
background: rgba(255, 255, 255, 0.09);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.2px);
-webkit-backdrop-filter: blur(5.2px);
border: 1px solid rgba(255, 255, 255, 0.43);

}

table {
	/* width: 800px; */
  width:95vw;
	border-collapse: separate;
	/* overflow: hidden; */
	box-shadow: 0 0 20px rgba(0,0,0,0.1);
  border-spacing: 0;

}

tr:nth-child(odd) {
  background: rgba(255, 255, 255, 0.09);
}

tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.1);

}

table thead th:first-child{
  border-top-left-radius: 10px;
}

table thead th:last-child{
  border-top-right-radius: 10px;
}

th,
td {
	padding: 15px;
	background-color: rgba(255,255,255,0.2);
	color: #ffffff;
}

th {
  position: sticky;
  top: 0;
  background-color: rgba(255, 255, 255, 0.5);
  color:#222;
  backdrop-filter: blur(15.2px);
  -webkit-backdrop-filter: blur(15.2px);

}

thead {
	th {
		background-color: #a9a9a98b;
	}
}










h1 {
  text-shadow: 0 2px 1px rgba(#fff, 0.3);
  text-align: center;
  letter-spacing: -0.05rem;
  color: #222;
  margin: 0;
}

h2 {
  text-align: center;
  margin: 0.5rem 0 2rem 0;
  color: rgba(255, 255, 255, 0.6);
}

.grid-table {
  display: grid;
  margin: 0 auto;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
  align-items: top;
  background: rgba(#fafafa, 0.9);
  border-radius: 0.5rem;

  @media (max-width: 800px) {
    background: transparent;
    grid-row-gap: 2rem;
    box-shadow: none;
  }

  &-row {
    display: grid;
    background-color: red;
    grid-template-columns: repeat(2, 2fr) repeat(2, 1fr);

    @media (max-width: 800px) {
      grid-template-columns: 3fr 1fr 3fr;
      background: rgba(#fafafa, 0.9);
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.2);
      border-radius: 0.5rem;
    }

    @media (max-width: 400px) {
      grid-template-columns: 1fr 1fr;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }

    &:first-child {
      border-radius: 0.5rem 0.5rem 0 0;
      background: rgba(#ccc, 0.5);
      font-weight: bold;
      text-shadow: 0 2px 1px #fff;

      @media (max-width: 800px) {
        display: none;
      }
    }
  }

  &-cell {
    padding: 1rem;

    @media (max-width: 800px) {
      padding: 0 0 1rem 0;
      text-align: center;
    }

    &:not(:last-child) {
      border-right: 1px solid #ddd;
    }

    @media (max-width: 800px) {
      &:before {
        content: attr(data-title);
        font-weight: bold;
        display: block;
        background: rgba(#ddd, 0.8);
        padding: 0.75rem;
        margin-bottom: 0.75rem;
        font-size: 1rem;
      }

      &:first-child {
        grid-column-start: 1;
        grid-column-end: 5;
        border-bottom: 1px solid #ddd;

        &:before {
          border-radius: 0.5rem 0.5rem 0 0;
        }
      }
    }

    @media (max-width: 400px) {
      &:last-child {
        grid-column-start: 1;
        grid-column-end: 5;
      }
    }
  }
}