body {
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;

}


input:valid { color: green }
input:invalid { color: red }

.invalid{
    visibility: visible;
}
.valid{
    visibility: invisible;
}

.validation {
    background-color: #EB9486;
    width: 70vw;
    font-variant: small-caps;
}

form > div > span.error {
	display: none;
	font-size: 0.8em;
	position: absolute;
}

form > div > input:invalid:not(:focus):not(:placeholder-shown) ~ .error {
	display: block;
	color: red;
}

.App {
    height: 100vh;
    padding: 0px 100px;
    display: flex;
    align-items: center;
    justify-content: center;

}

label {
    text-align: left;
    background-color: rgb(235, 235, 235, 0.8);
    /* width: 200px; */
    margin-left: 10px;


}

input {
    text-align: left;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    background-color: rgb(255, 255, 255, 0.8);
    margin-left: 15px;

}

.logo {
    height: initial;
    width: 200px;
    position: fixed;
    top: 0
}

.appMenu {
    height: initial;
    width: 200px;
    position: fixed;
    top: 80px;
    z-index: 9999;

}

.appSec {
    width: 100vw;
    font-size: medium;
}

.appSecHeader {
    height: initial;
    position: fixed;
    top: 10px;
    left: 10px;
}

.appSecBody {
    position: initial;
    position: fixed;
    font-size: small;
    top: 0px;
    left: 0px;
    height: 100%;
    width:100%;
    /* width: 80vw; */
    margin: 0px;

    overflow-y: scroll;
}

td {
    text-align: center;
}

th,
tr {
    text-align: center;
}


tr:nth-child(even).vacantSite {
    background-color: rgba(127, 255, 236, 0.8);
}

tr:nth-child(odd).vacantSite {
    background-color: rgb(127, 255, 212, 0.8);
}

.row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}


.books {
    display: flex;
    gap: 10px;

}

.book {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.table {
    overflow-y: scroll;
}

img {
    width: 200px;
    height: 300px;
    object-fit: cover;
    background-color: aquamarine;
}

.update {
    border: none;
    padding: 3px 6px;
    background-color: rgba(255, 255, 255, 0.3);
    cursor: pointer;
    border: 1px solid rgb(204, 204, 243);
    color: rgb(139, 139, 234);
}

.delete {
    border: none;
    padding: 3px 6px;
    background-color: white;
    cursor: pointer;
    border: 1px solid rgb(245, 191, 191);
    color: rgb(242, 100, 100);
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px
}

input {
    width: 250px;
    padding: 10px;
    border: 1px solid gray;
}

select {
    width: 250px;
    padding: 10px;
    border: 1px solid gray;
}

.formButton {
    border: none;
    padding: 10px;
    background-color: lightcoral;
    color: white;
    font-weight: bold;
    cursor: pointer;
}